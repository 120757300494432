import i18n from '@/i18n'

export default {
	// required: v => (!!v && !!v.length) || i18n.t('validationErrors.required'),
	required: v => {
		let result

		if (typeof v === 'string') {
			result = !!v.length
		} else if (typeof v === 'boolean') {
			result = !!v
		} else if (typeof v === 'number') {
			result = v > 0
		} else if (typeof v === 'object' && Array.isArray(v)) {
			result = !!v.length
		} else {
			result = !!v && !!v.length
		}

		return result === true || i18n.t('validationErrors.required')
	},

	requiredFile: v => (!!v && typeof v === 'object') || i18n.t('validationErrors.required'),
	email: v => /.+@.+/.test(v) || i18n.t('validationErrors.email'),
}
