<i18n>
{
  "en": {
		"noItemsText": "No invoices to show."
	},
  "fi": {
		"noItemsText": "Laskuja ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<!-- Output child page -->
		<router-view />

		<!-- Output navigation if we're not on child page -->
		<template v-if="$route.name == 'invoiceContainer'">
			<v-container
				v-if="ready === false"
				class="container--narrow"
			>
				<Spinner />
			</v-container>
			<v-container
				v-else
				class="container--narrow"
			>
				<InvoiceList
					:items="items"
					:no-items-text="$i18n.t('noItemsText')"
				/>
			</v-container>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'InvoiceContainer',
	data: () => ({
		ready: false,
	}),
	computed: {
		...mapState({
			items: state => state.user.invoice,
		}),
	},
	mounted () {
		this.$api.Me.doRequest().then(() => {
			this.ready = true
		})
	},
}
</script>
