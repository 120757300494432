export default {
	general: {
		save: 'Save',
		cancel: 'Cancel',
		yes: 'Yes',
		no: 'No',
		goBack: 'Go back',
		changeCookieSettings: 'Change your cookie settings',
		readPrivacyPolicy: 'More information about privacy policy',
	},
	routes: {
		error404: {
			title: 'Page not found',
		},
		membershipCard: {
			title: 'Card',
		},
		myAccount: {
			title: 'Edit your profile',
			headline: 'Edit your profile',
		},
		addresses: {
			title: 'Edit address information',
			headline: 'Edit address information',
		},
		subchapters: {
			title: 'Subsections',
			headline: 'Subsections',
		},
		degrees: {
			title: 'Degrees',
			headline: 'Degrees',
		},
		jobContainer: {
			title: 'Jobs',
			headline: 'Jobs',
		},
		discount: {
			title: 'Apply for membership fee discount',
			headline: 'Apply for membership fee discount',
		},
		benefitContainer: {
			title: 'Benefits',
			headline: 'Benefits',
		},
		benefit: {
			title: 'Benefit',
			headline: 'Benefit',
		},
		eventContainer: {
			title: 'Events',
			headline: 'Events',
		},
		event: {
			title: 'Event',
			headline: 'Event',
		},
		newsContainer: {
			title: 'News',
			headline: 'News',
		},
		news: {
			title: 'News article',
			headline: 'News article',
		},
		pageContainer: {
			title: 'Materials',
			headline: 'Materials',
		},
		page: {
			title: 'Page',
			headline: 'Page',
		},
		invoiceContainer: {
			title: 'Invoices',
			headline: 'Invoices',
		},
		invoice: {
			title: 'Invoice',
			headline: 'Invoice',
		},
		settings: {
			title: 'Settings',
			headline: 'Settings',
		},
	},
	cookieTypes: {
		necessary: 'Necessary',
		statistics: 'Statistics',
		marketing: 'Marketing',
	},
	validationErrors: {
		required: 'This field is required.',
		email: 'Please check e-mail address formatting.',
	},
}
