<i18n>
	{
	"en": {
			"currentWorkSituation": "My current work situation",
			"fromEmployeeToEntrepreneur": "If you are an SAFE-TAK joint member. Please note that your joint membership can be terminated, as entrepreneurs cannot be SAFA-TEK joint members. Please also change your situation with KOKO-cash register fund, if you are a member",
			"editWorkSituation": "Edit your work situation",
			"title": "Jobs",
			"addNewJob": "Add new job",
			"noItemsText": "No jobs to show"
		},
	"fi": {
			"currentWorkSituation": "Nykyinen työtilanteeni",
			"fromEmployeeToEntrepreneur": "Mikäli olet SAFA-TEK-yhteisjäsen, huomioithan että yhteisjäsenyytesi voi katketa, sillä yrittäjät eivät voi olla SAFA-TEK-yhteisjäseniä. Ilmoitathan tilanteesi muutoksesta myös KOKO-kassaan, jos olet kassan jäsen",
			"editWorkSituation": "Muokkaa työtilannettasi",
			"title": "Työt",
			"addNewJob": "Lisää uusi työpaikka",
			"noItemsText": "Työpaikkoja ei löytynyt"
		}
	}
</i18n>

<template>
	<div>
		<v-container
			class="container--narrow"
		>
			<!-- Output child page -->
			<router-view :key="$route.fullPath" />

			<template
				v-if="$route.name == 'jobContainer'"
			>
				<v-container
					class="container--narrow"
				>
					<v-alert
						type="info"
						v-if="user.c_from_employee_to_entrepreneur_date"
					>
						{{ $i18n.t('fromEmployeeToEntrepreneur') }}
					</v-alert>

					<v-card>
						<v-card-text>
							<p v-if="user._current_worksituation">
								{{ $i18n.t('currentWorkSituation') }}
							</p>
							<h2
								v-if="
									user._current_worksituation"
							>
								{{ user._current_worksituation }}
							</h2>
						</v-card-text>
						<v-card-text>
							<v-card-actions>
								<v-btn
									color="primary"
									text
									@click="$router.push({ name: 'workSituation'})"
								>
									<v-icon left>
										mdi-pencil
									</v-icon>
									{{ $i18n.t('editWorkSituation') }}
								</v-btn>
							</v-card-actions>
						</v-card-text>
					</v-card>
				</v-container>
				<v-container
					class="container--narrow"
				>
					<v-card>
						<v-card-title>
							{{ $i18n.t('title') }}
						</v-card-title>
						<v-card-text>
							<FilterableList
								v-if="user._jobs && user._jobs.length"
								:items="user._jobs"
								icon-src="_icon"
								title-src="c_organisaation_nimi"
								subtitle-src="_work_details"
								summary-src="_work_period"
								:enable-click="(true)"
								:enable-search="(false)"
								@itemClick="itemClick"
							/>
							<v-alert
								type="info"
								v-else
							>
								{{ $i18n.t('noItemsText') }}
							</v-alert>
							<v-divider />
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn
								color="secondary"
								text
								@click="$router.push({ name: 'jobAddNew'})"
							>
								<v-icon left>
									mdi-briefcase
								</v-icon>
								{{ $i18n.t('addNewJob') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-container>
			</template>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'JobContainer',

	computed: {
		...mapState([
			'user',
			'config',
		]),
	},

	methods: {
		itemClick (item) {
			this.$router.push({ name: 'job', params: { pagename: item.name } })
		},
	},
}

</script>
