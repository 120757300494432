<template>
	<v-container
		v-if="ready === false"
		class="container--narrow"
	>
		<Spinner />
	</v-container>
	<v-container
		v-else
		class="container--narrow"
	>
		<v-card>
			<v-card-text>
				<SchemaToForm
					:debug="false"
					:schema="config.forms.jobs"
					:disabled="saveLoading"
					v-model="formData"
					@valid="formValid = true"
					@invalid="formValid = false"
					@change="$store.commit('setConfirmNavigation', true)"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="success"
					:loading="saveLoading"
					:disabled="saveLoading || !formValid"
					@click="save"
					text
				>
					{{ $i18n.t('general.save') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>

import SchemaToForm from '@/components/SchemaToForm'
import { mapState } from 'vuex'

export default {
	name: 'JobAddNew',
	components: {
		SchemaToForm,
	},
	data: () => ({
		ready: false,
		saveLoading: false,
		formData: {},
		formValid: false,
	}),

	computed: {
		...mapState([
			'user',
			'config',
		]),
	},
	mounted () {
		this.$nextTick(() => {
			this.ready = true
		})
	},
	methods: {
		save () {
			this.saveLoading = true

			this.$api.Me.doRequest({
				url: 'job-add-new',
				method: 'POST',
				body: {
					...this.formData,
				},
			}).on('done', res => {
				if (!res.body.error) {
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
						this.$router.replace({
							name: this.$route.matched[this.$route.matched.length - 2].name,
						})
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
}
</script>
