<template>
	<div>
		<v-text-field
			ref="field"
			:value="valueFormatted"
			readonly
			v-bind="$attrs"
			:hide-details="dialogOpen === true"
			@click="openDialog"
			@click:prepend="openDialog"
			@click:clear="$emit('input', null)"
		/>

		<v-dialog
			ref="dialog"
			v-model="dialogOpen"
			persistent
			width="290px"
		>
			<v-date-picker
				v-model="pickerValue"
				:locale="$i18n.locale"
				v-bind="{
					showWeek: true,
					scrollable: true,
					firstDayOfWeek: 1,
					localeFirstDayOfYear: 4,
					...pickerProps,
				}"
			>
				<v-btn
					text
					@click="dialogOpen = false"
				>
					{{ $i18n.t('general.cancel') }}
				</v-btn>
				<v-spacer />
				<v-btn
					text
					color="primary"
					@click="saveDate"
				>
					OK
				</v-btn>
			</v-date-picker>
		</v-dialog>
	</div>
</template>

<script>

export default {
	name: 'DatePicker',
	inheritAttrs: false,
	props: {
		value: {
			type: [String],
			required: false,
			default () {
				return null
			},
		},

		// Use current date as default?
		defaultToday: {
			type: [Boolean],
			required: false,
			default () {
				return false
			},
		},

		// Props for date picker
		pickerProps: {
			type: [Object],
			required: false,
			default () {
				return {}
			},
		},
	},
	data: () => ({
		dialogOpen: false,
		pickerValue: null,
	}),
	computed: {
		// Formatted value based on selected locale
		valueFormatted () {
			if (!this.value || !this.value.length) return null

			return new Date(this.value).toLocaleDateString(this.$i18n.locale)
		},
	},
	watch: {
		// When value changes, update picker value
		value: {
			immediate: true,
			handler (val) {
				this.pickerValue = val
			},
		},
	},
	mounted () {
		// Set default date to today, if requested
		this.$nextTick(() => {
			this.$nextTick(() => {
				if (!this.value && this.defaultToday === true) {
					this.$emit('input', new Date().toISOString().substring(0, 10))
				}
			})
		})
	},
	methods: {
		openDialog () {
			// Since the field loses it's focus when opening the dialog,
			// we will reset the validation to hide unnecessary errors.
			this.$refs.field.resetValidation()

			this.pickerValue = this.value
			this.dialogOpen = true
		},
		saveDate () {
			this.dialogOpen = false
			this.$emit('input', this.pickerValue)
		},
	},
}

</script>
