<i18n>
	{
	"en": {
			"jobInformation": "Professional information",
			"editJob": "Edit job details"
		},
	"fi": {
			"jobInformation": "Työtehtävän tiedot",
			"editJob": "Muokkaa työtietojasi"
		}
	}
</i18n>

<template>
	<div v-if="job">
		<template v-if="($route.name === 'job')">
			<v-container
				class="container--narrow"
			>
				<v-card>
					<v-card-title>
						{{ $i18n.t('jobInformation') }}
					</v-card-title>
					<v-card-text>
						<FilterableList
							v-if="job.details.length"
							:items="job.details"
							:enable-click="(false)"
							:enable-search="(false)"
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn
							color="secondary"
							text
							:to="{ name: 'jobEdit' }"
						>
							<v-icon left>
								mdi-pencil
							</v-icon>
							{{ $i18n.t('editJob') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-container>
		</template>
		<!-- Output child page -->
		<router-view
			:key="$route.fullPath"
			:job="job"
		/>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'Job',

	data: () => ({
		job: null,
	}),

	computed: {
		...mapState([
			'user',
			'config',
		]),
	},

	mounted () {
		this.setJob()
	},

	methods: {
		setJob () {
			this.job = this.user._jobs.find(job => {
				return job.name === this.$route.params.pagename
			})

			if (!this.job) {
				this.$router.replace({ name: 'Error404' })
			}
		},
	},
}
</script>
