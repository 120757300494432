<template>
	<v-container
		v-if="ready === false"
		class="container--narrow"
	>
		<Spinner />
	</v-container>
	<v-container
		v-else
		class="container--narrow"
	>
		<v-card>
			<v-card-title>
				{{ subchapter.title }}
			</v-card-title>
			<v-card-text>
				<SchemaToForm
					:debug="false"
					:schema="config.forms.subchapterCancellation"
					:disabled="formLoading"
					v-model="formData"
					@valid="formValid = true"
					@invalid="formValid = false"
					@change="$store.commit('setConfirmNavigation', true)"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="success"
					:loading="formLoading"
					:disabled="formLoading|| !formValid"
					@click="save"
					text
				>
					{{ $i18n.t('general.save') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import SchemaToForm from '@/components/SchemaToForm'
import { mapState } from 'vuex'

export default {
	name: 'Subchapter',

	components: {
		SchemaToForm,
	},

	data: () => ({
		ready: false,
		subchapter: null,
		formLoading: false,
		formData: {},
		formValid: false,
	}),

	computed: {
		...mapState([
			'user',
			'config',
		]),
	},

	mounted () {
		this.loadSubchapter()

		this.$nextTick(() => {
			this.ready = true
		})
	},

	methods: {
		loadSubchapter () {
			this.subchapter = this.user._membership_subchapters.find(subchapter => {
				return subchapter.name == this.$route.params.pagename
			})

			// Set form data
			this.formData = this.subchapter

			if (!this.subchapter) {
				this.$router.replace({ name: 'Error404' })
			}
		},

		save () {
			this.formLoading= true

			this.$api.Me.doRequest({
				method: 'POST',
				url: 'leave-subchapter',
				body: {
					...this.formData,
					id: this.subchapter.id,
				},
			}).on('done', res => {
				if (!res.body.error) {
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
						this.$router.replace({
							name: this.$route.matched[this.$route.matched.length - 2].name,
						})
					})
				}
			}).on('finish', () => {
				this.formLoading= false
			})
		},
	},
}
</script>
