<i18n>
{
  "en": {
		"invoiceNotification": {
			"text": "Your latest membership invoice is late. Please pay your invoice to enable your membership benefits.",
			"buttonText": "Show invoices"
		},
		"noItemsText": "No benefits to show."
	},
  "fi": {
		"invoiceNotification": {
			"text": "Viimeisin jäsenlaskusi on maksamatta. Maksamalla jäsenlaskusi saat SAFAn jäsenedut käyttöösi.",
			"buttonText": "Näytä laskut"
		},
		"noItemsText": "Jäsenetuja ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="user._restricted_mode === true"
			class="container--narrow"
		>
			<v-alert type="error">
				{{ $i18n.t('invoiceNotification.text') }}

				<div class="mt-3">
					<v-btn
						text
						:to="{ name: 'invoiceContainer' }"
					>
						{{ $i18n.t('invoiceNotification.buttonText') }}
					</v-btn>
				</div>
			</v-alert>
		</v-container>
		<template v-else>
			<!-- Output child page -->
			<router-view />
			<!-- Output navigation if we're not on child page -->
			<template v-if="$route.name == 'benefitContainer'">
				<v-container class="container--narrow">
					<v-row
						v-if="items.length"
						no-gutters
					>
						<v-col
							v-for="item in items"
							:key="item.id"
							cols="12"
							sm="6"
							md="4"
							xl="3"
							class="pa-3"
						>
							<v-card
								:to="{ name: 'benefit', params: { pagename: item.name } }"
								:class="[
									item.highlight === 1 ? 'yellow lighten-4' : null,
								]"
								height="100%"
							>
								<div class="pa-3">
									<v-img
										width="70"
										v-if="item.logo"
										:src="item.logo.url"
									/>
								</div>
								<v-card-title
									v-if="item.title"
									class="pt-0"
									v-html="item.title"
								/>

								<v-card-text
									v-if="item.summary"
									v-html="item.summary"
								/>
							</v-card>
						</v-col>
					</v-row>
					<v-alert
						v-else
						type="info"
					>
						{{ $i18n.t('noItemsText') }}
					</v-alert>
				</v-container>
			</template>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'BenefitContainer',
	computed: {
		...mapState({
			user: state => state.user,
			items: state => state.benefits,
		}),
	},
	mounted () {
		this.$api.Me.doRequest()
		this.$api.Benefits.doRequest()
	},
}
</script>
