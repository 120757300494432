import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Error404 from '@/views/Error404.vue'
import MembershipCard from '@/views/MembershipCard.vue'
import MyAccount from '@/views/MyAccount.vue'
import Addresses from '@/views/Addresses.vue'
import Subchapters from '@/views/Subchapters.vue'
import Subchapter from '@/views/Subchapter.vue'
import Degrees from '@/views/Degrees.vue'
import JobContainer from '@/views/JobContainer.vue'
import Job from '@/views/Job.vue'
import WorkSituation from '@/views/WorkSituation.vue'
import JobAddNew from '@/views/JobAddNew.vue'
import JobEdit from '@/views/JobEdit.vue'
import Discount from '@/views/Discount.vue'
import Settings from '@/views/Settings.vue'
import BenefitContainer from '@/views/BenefitContainer.vue'
import Benefit from '@/views/Benefit.vue'
import EventContainer from '@/views/EventContainer.vue'
// import Event from '@/views/Event.vue'
import NewsContainer from '@/views/NewsContainer.vue'
import News from '@/views/News.vue'
import PageContainer from '@/views/PageContainer.vue'
import Page from '@/views/Page.vue'
import InvoiceContainer from '@/views/InvoiceContainer.vue'
import Invoice from '@/views/Invoice.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'membershipCard',
		component: MembershipCard,
		meta: {
			icon: 'mdi-badge-account-horizontal',
		},
	},
	{
		path: '/myaccount',
		name: 'myAccount',
		component: MyAccount,
		meta: {
			icon: 'mdi-badge-account-horizontal',
			hidden: true,
		},
		children: [
			{
				path: 'addresses',
				name: 'addresses',
				component: Addresses,
				props: true,
				meta: {
					icon: 'mdi-map-marker',
				},
			},
			{
				path: 'subchapters',
				name: 'subchapters',
				component: Subchapters,
				meta: {
					title: 'Alaosastot',
					headline: 'Alaosastot',
					icon: 'mdi-map-marker',
				},
				children: [
					{
						path: ':pagename',
						name: 'subchapter',
						component: Subchapter,
						meta: {
							title: 'Alaosasto',
						},
					},
				],
			},
			{
				path: 'degrees',
				name: 'degrees',
				component: Degrees,
				meta: {
					title: 'Tutkinnot',
					headline: 'Tutkinnot',
					icon: 'mdi-school',
				},
			},
			{
				path: 'jobs',
				name: 'jobContainer',
				component: JobContainer,
				meta: {
					title: 'Työt',
					headline: 'Työt',
					icon: 'mdi-briefcase',
				},
				children: [
					{
						path: 'work-situation',
						name: 'workSituation',
						component: WorkSituation,
						meta: {
							title: 'Työtilanne',
							headline: 'Työtilanne',
						},
					},
					{
						path: 'job-add-new',
						name: 'jobAddNew',
						component: JobAddNew,
						meta: {
							title: 'Lisää uusi työpaikka',
							headline: 'Lisää uusi työpaikka',
						},
					},
					{
						path: ':pagename',
						name: 'job',
						component: Job,
						meta: {
							title: 'Työpaikka',
						},
						children: [
							{
								path: 'job-edit',
								name: 'jobEdit',
								component: JobEdit,
								meta: {
									title: 'Muokkaa työpaikkaa',
									headline: 'Muokkaa työpaikkaa',
								},
							},
						],
					},
				],
			},
			{
				path: 'discount',
				name: 'discount',
				component: Discount,
				meta: {
					title: 'Jäsenallennukset',
					headline: 'Jäsenallennukset',
				},
			},
		],
	},
	{
		path: '/settings',
		name: 'settings',
		component: Settings,
		meta: {
			icon: 'mdi-cog',
			hidden: true,
		},
	},
	{
		path: '/news',
		name: 'newsContainer',
		component: NewsContainer,
		meta: {
			icon: 'mdi-bullhorn',
		},
		children: [
			{
				path: ':pagename',
				name: 'news',
				component: News,
				props: true,
			},
		],
	},
	{
		path: '/benefits',
		name: 'benefitContainer',
		component: BenefitContainer,
		meta: {
			icon: 'mdi-star',
		},
		children: [
			{
				path: ':pagename',
				name: 'benefit',
				component: Benefit,
				props: true,
			},
		],
	},
	{
		path: '/events',
		name: 'eventContainer',
		component: EventContainer,
		meta: {
			icon: 'mdi-calendar',
		},
	},
	{
		path: '/pages',
		name: 'pageContainer',
		component: PageContainer,
		meta: {
			hidden: true,
			icon: 'mdi-file-document-multiple',
		},
		children: [
			{
				path: ':pagename',
				name: 'page',
				component: Page,
				props: true,
			},
		],
	},
	{
		path: '/invoices',
		name: 'invoiceContainer',
		component: InvoiceContainer,
		meta: {
			icon: 'mdi-receipt',
			hidden: true,
		},
		children: [
			{
				path: ':pagename',
				name: 'invoice',
				component: Invoice,
				props: true,
			},
		],
	},
	{
		name: 'error404',
		path: '/error404',
		component: Error404,
		meta: {
			hidden: true,
		},
	},
	{
		path: '*',
		redirect: {
			name: 'error404',
		},
		meta: {
			hidden: true,
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

// Navigation guards
router.beforeEach((to, from, next) => {
	// If current route is not the first one the user visits,
	// we switch back button to the history mode.
	if (from.name !== null) {
		store.commit('setBackButtonHistoryMode', true)
	}

	// If navigation confirmation is requested, show dialog
	if (store.state.confirmNavigation) {
		store.commit('setConfirmNavigationDialog', true)
		store.commit('setConfirmNavigationRoute', to)
	} else {
		next()
	}
})

export default router
