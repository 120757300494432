<i18n>
{
  "en": {
		"titles": {
			"front": "SAFA's privacy policy"
		},
		"description": "We have updated the privacy policy for the member and subscriber registry. Review and accept the policy here.",
		"buttons": {
			"readMore": "Read the privacy policy",
			"acceptAll": "I have read and accepted the privacy policy for the member and subscriber registry"
		},
		"messages": {
			"saved": "Your privacy policy settings have been saved"
		}
	},
	"fi": {
		"titles": {
			"front": "SAFAn tietosuoja"
		},
		"description": "Olemme päivittäneet jäsen- ja tilaajarekisterin tietosuojaselosteen. Tutustu ja hyväksy seloste täällä.",
		"buttons": {
			"readMore": "Lue tietosuojaseloste tästä",
			"acceptAll": "Olen lukenut ja hyväksynyt jäsen- ja tilaajarekisterin tietosuojaselosteen"
		},
		"messages": {
			"saved": "Tietosuojavalintasi on tallennettu"
		}
	}
}
</i18n>
<template v-if="settings.showPrivacyDialog">
	<v-card>
		<template>
			<v-card-title class="text-h6">
				{{ $i18n.t('titles.front') }}
			</v-card-title>
			<v-card-text>
				{{ $i18n.t('description') }}
			</v-card-text>
			<v-card-text v-if="this.config.dataProtectionUrl.length">
				<v-btn
					:href="this.config.dataProtectionUrl"
					target="_blank"
				>
					{{ $i18n.t('buttons.readMore') }}
				</v-btn>
			</v-card-text>
			<v-divider />
			<v-card-text>
				<v-checkbox
					color="secondary"
					@click="dismissPrivacyDialog"
					:label="$i18n.t('buttons.acceptAll')"
				/>
			</v-card-text>
		</template>
	</v-card>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'PrivacyDialog',
	computed: {
		...mapState([
			'config',
			'settings',
		]),
	},
	methods: {
		dismissPrivacyDialog () {
			this.$store.dispatch('setSettings', {
				...this.settings,
				showPrivacyDialog: false,
			})

			this.$store.dispatch('setNotifyMessage', this.$i18n.t('messages.saved'))
			this.$emit('dismissPrivacyDialog', false)
		},
	},
}

</script>
