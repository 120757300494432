<i18n>
{
    "en": {
        "membership": "Membership",
        "addresses": "Addresses",
        "subchapters": "Subsections",
        "jobs": "Jobs",
        "degrees": "Degrees",
        "discount": "Membership Discount"
    },
    "fi": {
        "membership": "Jäsenyys",
        "addresses": "Osoitteet",
        "subchapters": "Alaosastot",
        "jobs": "Työt",
        "degrees": "Tutkinnot",
        "discount": "Jäsenmaksualennus"
    }
}
</i18n>

<template>
	<div>
		<v-toolbar
			color="secondary"
			dark
			flat
		>
			<v-tabs
				v-model="currentTab"
				centered
				center-active
				show-arrows
			>
				<v-tabs-slider />
				<v-tab
					v-for="tab in tabs"
					:key="tab.name"
					:to="tab.route"
					exact-path
					v-text="tab.title"
				/>
			</v-tabs>
		</v-toolbar>

		<v-container
			v-if="ready === false"
			class="container--narrow"
		>
			<Spinner />
		</v-container>
		<template v-if="($route.name === 'myAccount')">
			<v-container class="container--narrow">
				<v-card v-if="config.forms && config.forms.myAccount">
					<v-card-text>
						<SchemaToForm
							v-model="formData"
							:schema="config.forms.myAccount"
							:disabled="formLoading"
							:debug="false"
							@valid="formValid = true"
							@invalid="formValid = false"
							@change="$store.commit('setConfirmNavigation', true)"
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn
							color="primary"
							:loading="formLoading"
							:disabled="formLoading || !formValid"
							@click="save"
							text
						>
							{{ $i18n.t('general.save') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-container>
		</template>
		<template v-else>
			<router-view :key="$route.fullPath" />
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MyAccount',

	data: () => ({
		ready: false,
		formData: {},
		formSchema: {},
		formLoading: false,
		formValid: false,
		currentTab: 0,
	}),

	computed: {
		tabs () {
			return [
				{
					name: 'membership',
					title: this.$t('membership'),
					route: '/myaccount',
				},
				{
					name: 'addresses',
					title: this.$t('addresses'),
					route: '/myaccount/addresses',
				},
				{
					name: 'subchapters',
					title: this.$t('subchapters'),
					route: '/myaccount/subchapters',
				},
				{
					name: 'jobs',
					title: this.$t('jobs'),
					route: '/myaccount/jobs',
				},
				{
					name: 'degrees',
					title: this.$t('degrees'),
					route: '/myaccount/degrees',
				},
				{
					name: 'discount',
					title: this.$t('discount'),
					route: '/myaccount/discount',
				},
			]
		},
		...mapState([
			'user',
			'config',
		]),
	},
	mounted () {
		this.formData = JSON.parse(JSON.stringify(this.user))
		this.ready = true
	},

	methods: {
		save () {
			this.formLoading = true

			this.$api.Me.doRequest({
				method: 'POST',
				body: this.formData,
			}).on('done', (res) => {
				// Since the register may reformat values (i.e. telephone number),
				// replace form data with server response.
				if (res.body.memberData) {
					this.formData = res.body.memberData
				}

				// Clear navigation confirm
				if (!res.body.error){
					this.$api.Config.pushRequest()

					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
					})
				}
			}).on('finish', () => {
				this.formLoading = false
			})
		},
	},
}
</script>
