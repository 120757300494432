<template>
	<v-bottom-navigation
		app
		fixed
		dark
		color="white"
	>
		<template
			v-for="route in items"
		>
			<v-btn
				v-if="route.href"
				:key="route.name"
				:value="route.name"
				:href="route.href"
				target="_blank"
			>
				<span
					v-if="$i18n.te('routes.' + route.name + '.title')"
				>
					{{ $i18n.t('routes.' + route.name + '.title') }}
				</span>
				<v-icon
					v-if="route.meta.icon"
				>
					{{ route.meta.icon }}
				</v-icon>
			</v-btn>
			<v-btn
				v-else
				:key="route.name"
				:value="route.name"
				:to="route"
			>
				<span
					v-if="$i18n.te('routes.' + route.name + '.title')"
				>
					{{ $i18n.t('routes.' + route.name + '.title') }}
				</span>
				<v-icon
					v-if="route.meta.icon"
				>
					{{ route.meta.icon }}
				</v-icon>
			</v-btn>
		</template>
	</v-bottom-navigation>
</template>

<script>

export default {
	name: 'BottomBar',
	data: () => ({
		items: [],
	}),
	mounted () {
		// Get items to show
		this.items = this.$router.options.routes.filter(item => {
			return (!item.meta || !item.meta.hidden)
		})
	},
}
</script>
