<i18n>
	{
	"en": {
		"noItemsText": "No degrees to show."
	},
	"fi": {
		"noItemsText": "Tutkintoja ei löytynyt."
	}
	}
</i18n>

<template>
	<div>
		<v-container
			class="container--narrow"
		>
			<v-card>
				<v-card-text>
					<FilterableList
						v-if="user._entity_degrees && user._entity_degrees.length"
						:items="user._entity_degrees"
						icon-src="_icon"
						title-src="_title"
						subtitle-src="_subtitle"
						summary-src="_study_period"
						:enable-click="(false)"
						:enable-search="(false)"
					/>
					<v-alert
						type="info"
						v-else
					>
						{{ $i18n.t('noItemsText') }}
					</v-alert>
				</v-card-text>
			</v-card>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'Degrees',
	computed: {
		...mapState([
			'user',
			'config',
		]),
	},
}

</script>
