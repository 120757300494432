import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		dark: false,
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#f47938',
				secondary: '#f47938',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#f47938',
				success: '#4CAF50',
				warning: '#FFC107',
				text: '#000',
			},
			dark: {
				primary: '#FFFFFF',
				secondary: '#f47938',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#f47938',
				success: '#4CAF50',
				warning: '#FFC107',
				text: '#fff',
			},
		},
	},
	icons: {
		iconfont: 'mdi',
	},
})

