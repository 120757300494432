<template>
	<v-container
		class="container--narrow"
	>
		<v-card>
			<v-card-text>
				<SchemaToForm
					v-model="formData"
					:schema="config.forms.addresses"
					:disabled="formLoading"
					@valid="formValid = true"
					@invalid="formValid = false"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="primary"
					:loading="formLoading"
					:disabled="formLoading || !formValid"
					@click="save"
					text
				>
					{{ $i18n.t('general.save') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'AddressesForm',

	data: () => ({
		formData: {},
		formLoading: false,
		formValid: false,
	}),

	computed: {
		...mapState([
			'user',
			'config',
		]),
	},

	mounted () {
		this.formData = JSON.parse(JSON.stringify(this.user))
	},

	methods: {
		save () {
			this.formLoading = true

			this.$api.Me.doRequest({
				url: 'addresses',
				method: 'POST',
				body: this.formData,
			}).on('done', (res) => {
				if (res.body.error) return
			}).on('finish', () => {
				this.formLoading = false
			})
		},
	},
}
</script>
